<template>
	<div class="faq">
		<div class="container pt-5">
			<h2 class="my-5">Frequently Asked Questions (FAQs)</h2>


			<div v-for="(question,key) in questions" :key="key" class="pt-5 mb-5">
				<div class="row header" @click="question.show = !question.show">
					<div class="col"><h4>{{ question.question }}</h4></div>
					<div class="col-auto">
						<img :style="`${question.show ? 'transform: rotate(180deg)': ''}`" class="mt-3 toggler" alt="" src="/images/icons/down.svg">
					</div>
				</div>

				<hr class="divider">
				<div class="question" :class="`question ${question.show ?'active' : ''}`">
					<p class="my-3" v-for="(a,k) in question.answer" :key="k">{{ a }}</p>
				</div>
			</div>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../components/Footer";

export default {
	name: "FAQ",
	components: {Footer},
	data() {
		return {
			questions: [
				{
					show: true,
					question: "What is AlphaBatem Labs?", answer:
							["AlphaBatem is a metaverse technology company focused on providing next-generation digital infrastructure. We facilitate the growth of the Metaverse & ensure frictionless cohesion between integrated games and virtual worlds, using a standards based, open platform. "]
				},
				{
					show: true,
					question: "What does AlphaBatem Labs do?", answer:
							["AlphaBatem is the culmination of over ten years of work, and experience with next-generation technologies at scale, along with more than six years of industry and enterprise research in Augmented Reality (AR), Spatial Computing (SC) and Machine Learning (ML).",
								"AlphaBatem will provide consumers with the toolkit to build perpetual worlds, hosting up to 100,000 individuals while streaming live events. It will also provide consumers with the toolkit to integrate hyper segmented, granular advertising based on the preferences and interactions of their virtual worlds participants with plug and play integration for external companies who want to market."]
				},
				{
					show: true,
					question: "Why is AlphaBatem Labs different?", answer:
							["AlphaBatem’s vision is to make the metaverse as easy as possible for any one, whether technically inclined or not. And to enable people to be able to seamlessly build their virtual world. AlphaBatem Labs is bridging the gap and creating an accessible and inclusive gateway to  the decentralised ecosystem.\n"]
				},
				{
					show: true,
					question: "What value does AlphaBatem Labs add?", answer:
							["As the metaverse ecosystem grows a problem arises around the movement of users across multiple different metaverse platforms in a frictionless manner. Without an underlying common protocol to connect these metaverses together the user experience becomes clunky. AlphaBatem aims to resolve this through a standards-based protocol that allows disparate metaverses to become interconnected."]
				},
				{
					show: true,
					question: "What solutions has AlphaBatem Labs built?", answer:
							["AlphaBatem Labs has built various solutions for the metaverse, geospatial, retail, architecture, trainings and digital signage. For in-depth details, kindly view our product page."]
				},
				{show: true, question: "How long has AlphaBatem Labs existed?", answer: ["AlphaBatem Labs was founded in 2021."]},
				{
					show: true,
					question: "How can AlphaBatem Labs be  reached?", answer:
							["You can send an email to support@alphabatem.com",
								"You can also follow us on all our socials to stay updated on everything you need to know about the metaverse."]
				},
				{
					show: true,
					question: "Examples of AlphaBatem Labs works?", answer:
							["View our product page to see our industry use cases and our amazing range of products."]
				},
			]
		}
	}
}
</script>

<style scoped>
.question {
	transition: all 0.3s ease-in-out;
	max-height: 0;
	overflow: hidden;
}

.question.active {
	max-height: 900px;
}

.header {
	cursor: pointer;
}

.toggler {
	transition: all 0.3s ease;
}
</style>